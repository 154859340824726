export enum Role {
  Super_User = '671420cb44fa12822c58871c',
  Admin = '671420d344fa12822c58871e',
  User = '671420d944fa12822c588720',
}

export enum UserId {
  Amazon_Admin = '67143ed7f2d2934ae81d9c32',
  Walmart_Admin = '6714402df2d2934ae81d9c38',
  Amazon_User = '67143f2ef2d2934ae81d9c34',
  Walmart_User = '67143f89f2d2934ae81d9c36',
}
